<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //
            'id',
            'name',
            'real_name',
            'email',
            helpers.phoneNumber
          ]"
          :config="{ url: 'admin/managers', name: 'manager', _active: false }"
          :columnFilter="true"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Managers',
  mixins: [crudMixin]
}
</script>
