var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //
          'id',
          'name',
          'real_name',
          'email',
          _vm.helpers.phoneNumber
        ],"config":{ url: 'admin/managers', name: 'manager', _active: false },"columnFilter":true}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }